<template>
  <div>
    <div v-if="$route.name == 'Login' || $route.name == 'Register'  || $route.name == 'Reset' || $route.name == 'ResetPassword'" class="bg"></div>

    <v-snackbar v-model="snackbar">
      Vos données ont été mise à jour
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">Fermer</v-btn>
      </template>
    </v-snackbar>

    <!-- <v-app id="app" app elevate-on-scroll> -->
    <v-app  id="appBar">
      
    <v-app-bar fixed temporary  app  :height="90" color="white" elevate-on-scroll class="hidden-sm-and-down">
        <v-app-bar-nav-icon v-if="user"  @click="drawer = true" class="primary--text"> </v-app-bar-nav-icon>
            <img @click="$router.push('/')" width="220" height="90" src="https://www.cofidis.fr/fr/images/logo_baseline.svg" />
          <v-spacer></v-spacer>
    </v-app-bar>

    <v-navigation-drawer
        v-if="user"
        v-model="drawer"
        fixed
        bottom
        temporary
      >
        <img @click="$router.push('/carte')" width="180" height="90" src="@/assets/logo-real.png" />

         <v-divider></v-divider>
         
        <v-list
          nav
          dense
        >
          <v-list-item-group
            v-model="group"
            active-class="primary--text text--accent-4"
          >
            <v-list-item to="/">
              <v-list-item-icon>
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-icon>
              <v-list-item-title>Accueil</v-list-item-title>
            </v-list-item>

           
          </v-list-item-group>
        </v-list>


          <template v-slot:append>
            <div class="pa-2">
              <v-btn @click="logout" block color="secondary">
                 <v-icon>mdi-login</v-icon>
                Se déconnecter
              </v-btn>
            </div>
        </template>

    </v-navigation-drawer>

    <v-app-bar :height="90" app   color="white"  class="hidden-sm-and-up">
        <v-toolbar-title class="mx-auto pt-2">
            <img  @click="$router.push('/')" width="220" height="90" src="https://www.cofidis.fr/fr/images/logo_baseline.svg" />
        </v-toolbar-title>

        <v-dialog v-model="dialogMenu" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-app-bar-nav-icon dark slot="activator"></v-app-bar-nav-icon>
            <v-card>
              <v-toolbar>
                  <v-toolbar-title class="mx-auto pt-2">
                      <img  @click="$router.push('/')" width="110" height="50" src="https://www.cofidis.fr/fr/images/logo_baseline.svg" />
                  </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialogMenu = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
    
              </v-toolbar>

   
          <v-list nav dense>
            <v-list-item-group
              v-model="group"
              active-class="primary--text text--accent-4"
            >
              <v-list-item to="/" @click="dialogMenu = false">
                <v-list-item-icon>
                <v-icon>mdi-view-dashboard</v-icon>
              </v-list-item-icon>
                <v-list-item-title>Accueil</v-list-item-title>
              </v-list-item>

              <v-list-item to="/account"  @click="dialogMenu = false">
                  <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Mon profil</v-list-item-title>
              </v-list-item>

               <v-list-item to="/faq"  @click="dialogMenu = false">
                  <v-list-item-icon>
                  <v-icon>mdi-frequently-asked-questions</v-icon>
                </v-list-item-icon>
                <v-list-item-title>FAQ</v-list-item-title>
              </v-list-item>


               <v-list-item to="/infos"  @click="dialogMenu = false">
                    <v-list-item-icon>
                    <v-icon>mdi-information</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Infos</v-list-item-title>
                </v-list-item>

                 <v-list-item to="/contact"  @click="dialogMenu = false">
                      <v-list-item-icon>
                      <v-icon>mdi-email</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Contact</v-list-item-title>
                  </v-list-item>

              <v-list-item to="/premium" class="primary" dark  @click="dialogMenu = false">
                  <v-list-item-icon>
                 <v-icon class="pa-1">mdi-credit-card-outline</v-icon>
                 </v-list-item-icon>
                <v-list-item-title>En mode Premium</v-list-item-title>
              </v-list-item>

           
            </v-list-item-group>
          </v-list>

           <v-list rounded>
                <h4 class="pa-2 text-center">5 derniers inventaires</h4>
                <v-list-item-group
                      color="secondary">
                <v-list-item
                :value="inventaire"
                rounded="shaped"
                @click="loading(inventaire._id)"  v-for="inventaire in inventairesLoaded" :key="inventaire._id">
                  <v-list-item-title>
                    <v-icon  class="pa-2">mdi-seed</v-icon>{{ inventaire.name }}
                  </v-list-item-title>
                    <v-list-item-subtitle>
                          {{ inventaire.dateInventaire }}
                        </v-list-item-subtitle>
                    </v-list-item>
                    </v-list-item-group>
                </v-list>

              <!-- 
              <v-list>

                  <v-list-item v-for="(item, index) in nav" :key="index">
                      <v-list-item-content>
                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
              </v-list> -->
            </v-card>
        </v-dialog>
    </v-app-bar>
        
    <v-main id="main">
      <router-view />
    </v-main>
  
    <v-footer
        dark
        padless
      >
        <v-card
          flat
          tile
          color="primary"
          width="100%"
          class="white--text text-center"
        >
          <v-card-text>
            <v-btn
              v-for="icon in icons"
              :key="icon"
              class="mx-4 white--text"
              icon
            >
              <v-icon size="24px">
                {{ icon }}
              </v-icon>
            </v-btn>
          </v-card-text>

          <v-card-text class="white--text pt-0">
            Cofidis - Paiement en 4x
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text class="white--text">
            {{ new Date().getFullYear() }} — Made with love in <strong>Taiwa</strong>
          </v-card-text>
        </v-card>
    </v-footer>

</v-app> 
    
        

  </div>
</template>

<style>
#main{
  padding-top: 90px !important;
}
.opa {
  animation: fadeIn 2s;
  -webkit-animation: fadeIn 2s;
  -moz-animation: fadeIn 2s;
  -o-animation: fadeIn 2s;
  -ms-animation: fadeIn 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>

<script>
import xhr from './plugins/axios';
import { v4 as uuidv4 } from "uuid";
import axios from 'axios';
import moment from 'moment';
import "./App.scss";

const email = /^\S+@\S+\.\S+$/;
const long = /^.{3,}$/;
export default {
  name: "Login",
  data() {
    return {
      inventairesLoaded: [],
      drawer: false,
      icons: [
      'mdi-facebook',
      'mdi-twitter',
      'mdi-linkedin',
      'mdi-instagram',
    ],
    dialogMenu: false,
    nav: [
        {
          icon: 'home',
          text: 'Home',
          title: 'Back to Home page',
          active: true
        },
        {
          icon: 'info',
          text: 'About',
          title: 'About this demo',
          active: false
        },
        {
          icon: 'assignment_turned_in',
          text: 'Todos',
          title: 'Some stuff that needs doing',
          active: false
        },
      ],
      snackbarRooms: false,
      valid: true,
      manager: {
        id: uuidv4(),
        nom: "",
        prenom: "",
        email: "",
        password: "",
        property: "",
        admin: false,
      },
      loadingSync: false,
      dialog: false,
      connected: localStorage.getItem("user"),
      snackbar: false,
      annonce: "",
      select: "",
      dialogEditManager: false,
    };
  },
  watch: {
    $route(to, from) {
      if (from.name == "Edit") {
        this.snackbar = true;
      }
    },
  },
  async created() {

  },
  methods: {
    loading(inventaire){
      this.$router.push({ path: `/map/${inventaire}` })
      this.dialogMenu = false;
    },
    validate() {
      this.$refs.form.validate();
    },
    goto(refName) {
      const element = document.getElementById(refName);
      // element.scrollIntoView();
      this.$vuetify.goTo(element);
      // goTo(refName);
    },
    logout() {
      localStorage.clear();
      this.$router.push("/login");
    },
    add() {
      this.$router.push("/add");
    },
  },
};
</script>


