import Vue from 'vue';
import App from './App.vue';
import Vuetify from 'vuetify/lib';
import router from './router';
import fr from 'vuetify/es5/locale/fr';
import 'vue-material-design-icons/styles.css'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

import store from './store'

Vue.use(Vuetify);

// const options = {
//   pk: process.env.STRIPE_PUBLISHABLE_KEY,
//   stripeAccount: process.env.STRIPE_ACCOUNT,
//   apiVersion: process.env.API_VERSION,
//   locale: process.env.LOCALE,
// };

// Vue.use(StripePlugin, options);


let vuetify = new Vuetify({
    icons: {
        iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
    lang: {
        locales: { fr },
        current: 'fr',
    },
    theme: {
        themes: {
            light: {
                primary: '#D0103A',
                secondary: '#FECB00',
                accent: '#380E07',
                error: '#EF7D00',
            },
        },
    },
});


Vue.config.productionTip = false;

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app');