import axios from 'axios';

const username = 'Korian_-d3f5h4g56dfgh5_6df4h568f';
const password = 's354yhj5ty_5h-4564k56_h468kj356df135h35'
const token = `${username}:${password}`;
const encodedToken = Buffer.from(token).toString('base64');

const xhr = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  // baseURL: 'https://manager.conessence-pro.fr/api',
  // headers: { 'Authorization': 'Basic ' + encodedToken }
});

// JWT Web Token

// xhr.defaults.headers.common = {'Authorization': `bearer ${ localStorage.getItem('token')}`}

// Add a response interceptor
xhr.interceptors.response.use(
  response =>
    // Do something with response data
    response,
  error => {
    // Do something with response error
    return Promise.reject(error);
  }
);

export default xhr;
