import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () =>
      import(/* webpackChunkName: "about" */ '../components/Home.vue'),
  },
   {
    path: '/next',
    name: 'Next',
    component: () =>
      import(/* webpackChunkName: "about" */ '../components/Next.vue'),
  },

  {
    path: '/login',
    name: 'Login',
    component: () => import('../components/Login.vue'),
  },
  {
    path: '/sms',
    name: 'Sms',
    component: () => import('../components/Sms.vue'),
  },
  {
    path: '/success',
    name: 'Success',
    component: () => import('../components/Success.vue'),
  },
  
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   let token = localStorage.getItem('user');
//   if (token) {
//     token = JSON.parse(localStorage.getItem('user'));
//   }

//   if (token && to.name == 'Add' && token.admin !== true) {
//     return router.push({ name: 'Home' });
//   }
//   if (!token && to.name != 'Login'
//   && to.name != 'Slides'
//   && to.name != 'Home'
//   && to.name != 'Register' 
//   && to.name != 'Reset'
//   && to.name != 'ResetPassword'
//   ) {
//     return router.push({ name: 'Login' });
//   } else {
//     return next();
//   }
// });

export default router;
